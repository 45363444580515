code {
  font-family: Arial,'Courier New',
    monospace;
}

*{
  --switch-height: 17px;
  --switch-width: 34px;
  font-family: 'Segou UI', 'Calibri', Arial, 'Helvetica Neue', 'Times New Roman';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --primary-color: hsla(
    calc(var(--hue) + var(--BG-hue)), 
    calc(var(--sat) + var(--BG-sat)),
    calc(var(--light) + var(--BG-light)),
    calc(var(--opacity) + var(--BG-opacity)));

  --accent-color: hsla(
    calc(var(--hue) + var(--accent-hue)), 
    calc(var(--sat) + var(--accent-sat)),
    calc(var(--light) + var(--accent-light)),
    calc(var(--opacity) + var(--accent-opacity)));

  --font-color:hsla(
    calc(var(--hue) + var(--font-hue)), 
    calc(var(--sat) + var(--font-sat)),
    calc(var(--light) + var(--font-light)),
    calc(var(--opacity) + var(--font-opacity)));

  --contrast-color: hsla(
    calc(var(--hue) + var(--contrast-hue)), 
    calc(var(--sat) + var(--contrast-sat)),
    calc(var(--light) + var(--contrast-light)),
    calc(var(--opacity) + var(--contrast-opacity)));
}